import { AppBar, Toolbar, Typography, Button, Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { t } = useTranslation();

  return (
    <AppBar position="static" color="transparent" sx={{ marginBottom: 4 }}>
      <Toolbar>
        <Link color="inherit" underline="none">
          <Typography variant="h6" component="div">
            <Box
              sx={{
                verticalAlign: 'middle',
                paddingLeft: '1px',
                width: '200px',
                height: '70px',
              }}
              component="img"
              height="70"
              alt=""
              src="ostec-pt-br.svg"
            />
          </Typography>
        </Link>
        <Box
          sx={{
            marginLeft: 'auto',
          }}
        >
          <Button
            component={Link}
            onClick={() => (window.location.href = '/')}
            variant="contained"
            color="primary"
          >
            {t('header.buttonHome')}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
